import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { NotFoundPageComponent } from "./not-found-page/not-found-page.component";
import { StartComponent } from "./start/start.component";

const routes: Routes = [
  {
    path: "",
    component: NotFoundPageComponent,
  },
  {
    path: "DataEntry/:id",
    component: StartComponent,
  },
  {
    path: "DataConflict/:id",
    component: StartComponent,
  },
  {
    path: "DataEntryView/:id",
    component: StartComponent,
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
