import { OverlayConfig } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { AuthService } from '../auth/services/auth.service';
import { BlockModalComponent } from '../block-modal/block-modal.component';
import { CertimedDataEntrySearchModalComponent } from '../certimed-data-entry-search-modal/certimed-data-entry-search-modal.component';
import { CertimedMensuraSearchModalComponent } from '../certimed-mensura-search-modal/certimed-mensura-search-modal.component';
import { PerformaceDecrease, certimedDCInfo, certimedModel } from '../Interfaces/certimedDCInfo';
import { CommonInfoFolder } from '../Interfaces/commonInfoFolder';
import { Dictionary } from '../Interfaces/dictionary';
import { CommonControllerService } from '../services/common-controller.service';
import { DataEntryControllerService } from '../services/data-entry-controller.service';
import { DateHas20XXYear, IsValidDate, IsTodayOrPastDate } from '../validators/DatesValidations';
import { ValueInListValidator } from '../validators/ValueInList';
interface De8JSON {
  DiagnosisId: string;
  ICD10Code:   string;
  Description: string;
}

@Component({
  selector: 'app-certimed-data-entry-viewer',
  templateUrl: './certimed-data-entry-viewer.component.html',
  styleUrls: ['./certimed-data-entry-viewer.component.css']
})
export class CertimedDataEntryViewerComponent implements OnInit {

  @Input() dataEntryInfo: Dictionary;
  @Input('startDateTime') startDateTime: Date;
  @Input('productionView') productionView: boolean;
  @Input('displayGoogleSearch') shouldDisplayGoogleSearch: boolean = true;
  @Input('isSaved') isSaved: boolean;
  @Input('commonInfo') commonInfo: CommonInfoFolder;
  @Output('disableSave') disableSave = new EventEmitter<boolean>();
  

  @ViewChild('deform', { read: ViewContainerRef, static: false }) ref: ViewContainerRef;
  @ViewChild('deform', { static: false }) component: ElementRef;

  folderHash: string;
  user: string;
  certimedDCInfo: certimedDCInfo;
  certimedModel: certimedModel;
  idForm: string; //it is the name of the form needed for DC.
  isFilled: boolean = false;
  isDCView: boolean = false;
  overlayConfig: OverlayConfig = new OverlayConfig();
  disableSend: boolean = false;
  disableBlock: boolean = false;
  disableClose: boolean = false;
  firstTime: boolean = true;
  isSearchDone: boolean = false;

  //LIST BOX
  verlatenVanDeWoningOptions: Array<string>;
  redenArbeidsongeschiktheidOptions: Array<string>;
  attestTypeOptions: Array<string>;
  firmanaamOptions: Array<string>;
  resultaatControleOptions: Array<string>;
  verblijfplaatsLandcodeOptions: Array<string>;
  documentTypeOptions: Array<string>;
  diagnosisAllowedDE2Options: Array<string>;
  redenArbeidsongeschiktheidEnableDiagnisis: Array<string>;

  //VAR FOR SHOW THE DIFFERENTS FORM OF DATA_ENTRY
  showDE1: boolean = false;
  showDE2: boolean = false;
  showDE3: boolean = false;
  showDE4: boolean = false;
  showDE5: boolean = false;
  showDE6: boolean = false;
  showDE7: boolean = false;
  showDE8: boolean = false;
  showDE9: boolean = false;
  showDE10: boolean = false;
  showMensuraOneDay: boolean = false;
  showUnknown: boolean = false;
  showESResult: boolean = false;
  resultFound: boolean = false;

  //VAR FOR SHOW THE DIFFERENTS ERROR ON DATES.
  showErrorHospitalizationDate: boolean = false;
  errorHospitalizationDateMessage: string = "";
  showErrorOccupationalDisabilityDate: boolean = false;
  errorOccupationalDisabilityDateMessage: string = "";
  showErrorTravelAbroadDate: boolean = false;
  errorTravelAbroadDateMessage: string = "";
  showErrorPerformanceDecreaseDate: boolean = false;
  errorPerformanceDecreaseDateMessage: string = "";
  showErrorTemporaryResidenceDate: boolean = false;
  errorTemporaryResidenceDateMessage: string = "";

  //OTHERS
  heightWindow: string; //We use that for stablishing the height in the scroll.
  datemask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  patternDate = /([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})/;
  timeMask = [/\d/, /\d/, ':', /\d/, /\d/];
  patternTime = /(0[789]|1[0-9]|20):([0-5][0-9])|21:00/;
  
  // DE8 process autocomplete options
  DE8Options: string[] = [];
  filteredOptions: Observable<string[]>;
  diagnosisCert: string;
  
  performanceDecreaseGroup = {
    performanceDecreaseStart: ['', [IsValidDate(), DateHas20XXYear()]],
    performanceDecreaseEnd: ['', [IsValidDate(), DateHas20XXYear()]]
  }

  dataEntryForm = new FormGroup({

    //VAR OF DATA ENTRY PROCESS 1
    de1: new FormGroup({
      patientFirstName: new FormControl('', [Validators.required]),
      patientLastName: new FormControl('', [Validators.required]),
      nationalNumber: new FormControl(),
      personnelNumber: new FormControl()
    }),

    //VAR OF DATA ENTRY PROCESS 2
    de2: new FormGroup({
      allowLeavingHome: new FormControl('', [Validators.required]),
      incapacityReason: new FormControl('', [Validators.required]),
      hospitalizationStart: new FormControl('', [IsValidDate(), DateHas20XXYear()]),
      hospitalizationEnd: new FormControl('', [IsValidDate(), DateHas20XXYear()]),
      occupationalDisabilityStart: new FormControl('', [IsValidDate(), DateHas20XXYear()]),
      occupationalDisabilityEnd: new FormControl('', [IsValidDate(), DateHas20XXYear()]),
      certificateType: new FormControl('', [Validators.required]),
      certificateDate: new FormControl('', [IsValidDate(), DateHas20XXYear()]),
      signature: new FormControl('', [Validators.required]),
      stamp: new FormControl('', [Validators.required]),
      doctorName: new FormControl(),
      doctorPhoneNumber: new FormControl(),
      rizivNumber: new FormControl(),
      travelAbroadStart: new FormControl('', [IsValidDate(), DateHas20XXYear()]),
      travelAbroadEnd: new FormControl('', [IsValidDate(), DateHas20XXYear()])
    }),

    //VAR OF DATA ENTRY PROCESS 3
    de3: new FormGroup({
      patientStreet: new FormControl('', [Validators.required]),
      patientHouseNumber: new FormControl('', [Validators.required]),
      patientBusNumber: new FormControl(),
      patientPostalNumber: new FormControl(),
      patientCity: new FormControl('', [Validators.required]),
      patientCountryCode: new FormControl()
    }),

    //VAR OF DATA ENTRY PROCESS 4
    de4: new FormArray([this.fb.group(this.performanceDecreaseGroup)]),

    //VAR OF DATA ENTRY PROCESS 5
    de5: new FormGroup({
      companyName: new FormControl('', [Validators.required]),
      companyMensuraId: new FormControl(),
      resultDoctorId: new FormControl(),
      resultDoctorFirstName: new FormControl(),
      resultDoctorLastName: new FormControl(),
      resultCheck: new FormControl('', [Validators.required]),
      resultDate: new FormControl('', [CertimedDataEntryViewerComponent.validateResultDate, IsValidDate(), DateHas20XXYear()]),
      resultTime: new FormControl('', [Validators.pattern(this.patternTime)])
    }),

    //VAR OF DATA ENTRY PROCESS 6
    de6: new FormGroup({
      temporaryResidenceStart: new FormControl('', [IsValidDate(), DateHas20XXYear()]),
      temporaryResidenceEnd: new FormControl('', [IsValidDate(), DateHas20XXYear()]),
      temporaryResidenceName: new FormControl(),
      temporaryResidenceStreet: new FormControl(),
      temporaryResidenceHouseNumber: new FormControl(),
      temporaryResidenceBusNumber: new FormControl(),
      temporaryResidencePostalNumber: new FormControl(),
      temporaryResidenceCity: new FormControl(),
      temporaryResidenceCountryCode: new FormControl('', [Validators.required]),
    }),

    //VAR OF DATA ENTRY PROCESS 7
    de7: new FormGroup({
      companyName: new FormControl('', /*[Validators.required]*/)
    }),

    //VAR OF DATA ENTRY PROCESS 8
    de8: new FormGroup({
      diagnose: new FormControl('', [Validators.required, ValueInListValidator()])
    }),

    //VAR OF DATA ENTRY PROCESS 9
    de9: new FormGroup({
      certificatePostmarkdate: new FormControl('', [IsValidDate(), DateHas20XXYear(), IsTodayOrPastDate()])
    }),

    //VAR OF DATA ENTRY PROCESS 10
    de10: new FormGroup({
      homePhoneNumber: new FormControl(''),
      mobilePhoneNumber: new FormControl(''),
      senderEmail: new FormControl('')
    }),

    //VAR OF MENSURA ONE DAY
    deMOD: new FormGroup({
      companyMensuraId: new FormControl(),
      personnelNumber: new FormControl('', [Validators.maxLength(11), Validators.minLength(11), Validators.pattern("[0-9]{11}")]),
      occupationalDisabilityStart: new FormControl('', [Validators.pattern(this.patternDate), Validators.required, CertimedDataEntryViewerComponent.validateOccupationalDisabilityStart]),
      occupationalDisabilityEnd: new FormControl()
    }),

    //VAR OF UNKNOWN
    deUnk: new FormGroup({
      documentType: new FormControl('', [Validators.required])
    })
  });

  //SHORTCUTS
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if (!this.productionView) {
      if (event.ctrlKey && event.code == "KeyS") { //CTRL + S
        //We need to save it
        if (!this.isDCView) {
          event.preventDefault()
          event.stopImmediatePropagation();
          if (!this.disableSend) {
            this.dataEntryForm.markAllAsTouched();
            console.log(this.dataEntryForm.value);
            
            this.onSubmit(this.dataEntryForm.value);
          }
        }
      }
      else if (event.ctrlKey && event.code == "KeyB") { //CTRL + B
        //We need to block it
        event.preventDefault()
        event.stopImmediatePropagation();
        if (!this.disableBlock)
          this.block();
      }
      else if (event.ctrlKey && event.code == "KeyQ") { //CTRL + Q
        //We need to close it
        event.preventDefault()
        event.stopImmediatePropagation();
        if (!this.disableClose)
          this.close();
      }
    }

  }

  constructor(private dataEntryControllerService: DataEntryControllerService, 
    private notification: MatSnackBar, 
    public dialog: MatDialog, 
    private commonControllerService: CommonControllerService,
    private http: HttpClient,
    private authService: AuthService,
    private fb: FormBuilder
    ) {  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isSaved != undefined) {
      if (!this.firstTime) {
        if (this.dataEntryForm.invalid) {
          this.dataEntryForm.markAllAsTouched();
          this.disableSave.emit(false);
          this.isSaved = !this.isSaved;
        }
        else {
          this.onSubmit(this.dataEntryForm.value);
        }
      }
      else
        this.firstTime = false;
    }

  }

  ngOnInit() {

    this.verlatenVanDeWoningOptions = new Array<string>("Toegelaten / Autorisée", "Verboden / Interdite", "Not Found");
    this.redenArbeidsongeschiktheidOptions = new Array<string>(
      "ziekte",
      "maladie",
      "arbeidsongeval",
      "accident du travail",
      "chronische ziekte",
      "eendagsziekte zonder attest",
      "ernstige ziekte",
      "maladie grave",
      "langdurige verminderde prestaties",
      "leefloon",
      "prive ongeval",
      "sport ongeval",
      "ongeval",
      "accident",
      "profylactisch verlof",
      "reis buitenland",
      "demande de séjour à l'étranger",
      "therapeutisch verlof",
      "prestations réduites à des fins thérapeutiques",
      "verminderde prestaties",
      "demande de mi-temps médical",
      "vernieuwing missieverlof",
      "renouvellement de mission",
      "ziekte tgv zwangerschap",
      "maladie liée à la grossesse",
      "zwangerschapsverlof",
      "congé de maternité",
      "niet vermeld",
      "malade pendant le congé",
      "ziek tijdens verlof"
    );
    this.diagnosisAllowedDE2Options = [
      "ziekte",
      "maladie",
      "zwangerschapsverlof",
      "congé de maternité",
      "arbeidsongeval",
      "prive ongeval",
      "sport ongeval",
      "niet vermeld",
      "malade pendant le congé",
      "ziek tijdens verlof",
      "ongeval",
      "accident",
    ]
    this.attestTypeOptions = new Array<string>("Not found", "1er attest", "herval", "premier constat", "prolongation", "rechute", "verlenging");
    this.firmanaamOptions = new Array<string>("Onderwijs", "verzekeringen", "andere", "education", "assurances", "autres");
    this.resultaatControleOptions = new Array<string>("Eigen Praktijk", "Bij de werknemer", "dans la pratique", "à la maison de l'employée", "Huisbezoek", "Not present");
    this.verblijfplaatsLandcodeOptions = new Array<string>("B", "F", "NL", "L", "all others");
    this.documentTypeOptions = new Array<string>(
      "Ongekend",
      "Ongekend Info Dokters",
      "Ongekend Spontane Werkhervatting",
      "Ongekend Onkosten Nota",
      "Ongekend AGD",
      "Ongekend Vlaams Onderwijs - NO ID",
      "Ongekend - Hyperlink to document in mail",
      "Ongekend - Document mentioned but not found",
      "Ongekend - Not Readable"
    );

    this.folderHash = this.commonInfo.folderHash;

    if (this.dataEntryInfo)
      this.idForm = "dataEntryForm_" + this.dataEntryInfo.key;
    else
      this.idForm = "dataEntryForm";

    this.startInfo();

    var toolbarHeight = (document.getElementsByClassName("mat-toolbar-row").item(0) as HTMLElement).offsetHeight;
    this.heightWindow = document.documentElement.clientHeight - toolbarHeight + "px";
    document.getElementById("viewportDE").style.height = this.heightWindow;

    this.http.get("assets/JSON/Diagnose.json").subscribe((data: De8JSON[]) => {
      this.DE8Options = data.map((value: De8JSON) => {
        return `${value.Description}, ${value.ICD10Code}`
      });
    });

    this.filteredOptions = this.dataEntryForm.get("de8.diagnose").valueChanges.pipe(
      startWith(''),
      map(value => this.filterOptions(value))
    );

  }

  private filterOptions(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.DE8Options.filter(option => {
      return option.toLowerCase().includes(filterValue);
    });
  }

  async onSubmit(dataEntryForm) {
    console.log(dataEntryForm);
    this.disableSend = true;
    if (this.dataEntryForm.invalid) {
      console.log("invalid");
      this.disableSend = false;
    }
    //Check if the search has been done (in case of data entry process 5)
    else if ((this.showDE2 && !this.isSearchDone) || (this.showMensuraOneDay && !this.isSearchDone))
    {
      console.log("Not search done!")
      this.notification.open("You need to do the search before save the Data entry.", "", {
        duration: 2000,
        panelClass: "centeredNotification"
      }).afterDismissed().toPromise().then(x => {
        this.disableSend = false;
        this.disableSave.emit(false);
      });
    }
    //Check if dates are correct
    else if (!this.datesAreValid()) {
      this.disableSend = false;
      this.disableSave.emit(false);
    }
    else {
      //We transform the de in order to get the format of the model.
      var form = this.quitFormGroup(dataEntryForm);
      console.log(form);
      this.transformValueSelect(form);
      console.log(form);
      var result = await this.dataEntryControllerService.SendForm(form, this.user, this.folderHash, "CERTIMED", this.startDateTime, this.commonInfo.status);
      this.notification.open(result, "", {
        duration: 2000,
        panelClass: "centeredNotification"

      }).afterDismissed().toPromise().then(x => {
        if (result == "The form has been filled susccessfully.") {
          self.close();
        }
        else {
          this.disableSend = false;
          this.disableSave.emit(false);
        }
      });
    }
    return false;
  }

  getErrorRequiredMessage(field: AbstractControl) {
    let msg: string = '';

    if (field.hasError('required'))
      msg += 'You need to enter a value\n';

    if (field.hasError('IsValidDate'))
      msg += 'Invalid date\n';
    else {
      if (field.hasError('DateHas20XXYear'))
        msg += 'Year should be in format 20XX\n';
  
      if (field.hasError('IsTodayOrPastDate'))
        msg += 'Future dates are not allowed\n';
    }
    
    return msg;
  }

  updateAdderssValues(event, dataEntryProcess: string) {
    let formGroup: FormGroup = this.dataEntryForm.get(dataEntryProcess) as FormGroup;
    const {city, country_code, postal_code, street, street_number} = event;

    switch (dataEntryProcess) {
      case 'de3':
        formGroup.get("patientStreet").setValue(street);
        formGroup.get("patientHouseNumber").setValue(street_number);
        formGroup.get("patientPostalNumber").setValue(postal_code);
        formGroup.get("patientCity").setValue(city);
        formGroup.get("patientCountryCode").setValue(this.getCountryCodeMappedValue(country_code));
        break;
    
      case 'de6':
        formGroup.get("temporaryResidenceStreet").setValue(street);
        formGroup.get("temporaryResidenceHouseNumber").setValue(street_number);
        formGroup.get("temporaryResidencePostalNumber").setValue(postal_code)
        formGroup.get("temporaryResidenceCity").setValue(city);
        formGroup.get("temporaryResidenceCountryCode").setValue(this.getCountryCodeMappedValue(country_code));
        break;
    }
  }

  getCountryCodeMappedValue(googleMapsApiCountryCode) {    
    switch (googleMapsApiCountryCode) {
      case 'BE':
        return "B";
    
      case 'FR':
        return "F";

      case 'LU':
        return "L"  

      default:        
        return "ALL OTHERS"
    }
  }

  async startInfo() {
    let info: string | CommonInfoFolder;

    this.diagnosisCert = (await this.dataEntryControllerService.GetFolderIdentification(this.folderHash))["diagnosisCert"];

    if (this.productionView) {
      info = await this.commonControllerService.GetDataEntryType(this.folderHash);
      this.showDEForm(info as string);
    }
    else {
      info = await this.commonControllerService.GetCommonInfo(this.folderHash);
      console.log("info", info)
      this.showDEForm(info.typeDE);
    }
    
    this.user = this.authService.getUsername();

    if (this.dataEntryInfo) {
      this.isDCView = true;
      if (this.dataEntryInfo.key != "dc") {

        this.isFilled = true;
        this.showESResult = true;
        this.dataEntryForm.markAllAsTouched();

        this.certimedDCInfo = JSON.parse(this.dataEntryInfo.value);
        this.certimedModel = this.certimedDCInfo.dataEntryValue;
        this.fillTheForm();

        if (this.dataEntryInfo.key != "de3") {
          this.updateValidations();
        }

      }
      else {
        this.certimedDCInfo = JSON.parse(this.dataEntryInfo.value);
        this.certimedModel = this.certimedDCInfo.dataEntryValue;
        this.showESResult = true;

        try {
          this.fillTheForm();
        }catch(err) {
          console.log(err);
        }
      
        this.isSearchDone = this.validateSearchDC();
      }
    }

    if (this.showDE5 || this.showMensuraOneDay) {
      this.fillDoctorInfo();
    }

  }

  showDEForm(type: string) {

    if (type == "Mensura One Day" || type == "Unknown Document Type") {
      if (type == "Unknown Document Type") {
        this.showUnknown = true;
        this.dataEntryForm.controls["deMOD"].disable();
      }
      else {
        this.showMensuraOneDay = true;
        this.dataEntryForm.controls["deUnk"].disable();
      }
      
      this.dataEntryForm.controls["de1"].disable();
      this.dataEntryForm.controls["de2"].disable();
      this.dataEntryForm.controls["de3"].disable();
      this.dataEntryForm.controls["de4"].disable();
      this.dataEntryForm.controls["de5"].disable();
      this.dataEntryForm.controls["de6"].disable();
      this.dataEntryForm.controls["de7"].disable();
      this.dataEntryForm.controls["de8"].disable();
      this.dataEntryForm.controls["de9"].disable();
      this.dataEntryForm.controls["de10"].disable();
    }
    else {
      this.dataEntryForm.controls["deMOD"].disable();
      this.dataEntryForm.controls["deUnk"].disable();
      
      if (type[0] == '1')
        this.showDE1 = true;
      else {
        this.showDE1 = false;
        this.dataEntryForm.controls["de1"].disable();
      }

      if (type[1] == '1')
        this.showDE2 = true;
      else {
        this.showDE2 = false;
        this.dataEntryForm.controls["de2"].disable();
      }

      if (type[2] == '1')
        this.showDE3 = true;
      else {
        this.showDE3 = false;
        this.dataEntryForm.controls["de3"].disable();
      }

      if (type[3] == '1')
        this.showDE4 = true;
      else {
        this.showDE4 = false;
        this.dataEntryForm.controls["de4"].disable();
      }

      if (type[4] == '1')
        this.showDE5 = true;
      else {
        this.showDE5 = false;
        this.dataEntryForm.controls["de5"].disable();
      }

      if (type[5] == '1')
        this.showDE6 = true;
      else {
        this.showDE6 = false;
        this.dataEntryForm.controls["de6"].disable();
      }

      if ((type[6]) && type[6] == '1')
        this.showDE7 = true;
      else {
        this.showDE7 = false;
        this.dataEntryForm.controls["de7"].disable();
      }

      if(this.diagnosisCert.toLowerCase() == "found and typed") {
        this.showDE8 = true;

        this.dataEntryForm.get("de2.incapacityReason").valueChanges
        .pipe(filter(val => val !== null))
        .subscribe((value) => {      
          if (this.diagnosisAllowedDE2Options.includes(value.toLowerCase())) 
          {
            this.dataEntryForm.get("de8.diagnose").enable();
            this.showDE8 = true;  
          }else {        
            this.dataEntryForm.get("de8.diagnose").disable();
            this.showDE8 = false;      
          }
        });
      }
      else {
        this.showDE8 = false;
        this.dataEntryForm.controls["de8"].disable();
      }

      if((type[8]) && type[8] == '1')
        this.showDE9 = true;
      else {
        this.showDE9 = false;
        this.dataEntryForm.controls["de9"].disable();
      }

      if((type[9]) && type[9] == '1')
        this.showDE10 = true;
      else {
        this.showDE10 = false;
        this.dataEntryForm.controls["de10"].disable();
      }
    }
  }

  getErrorPercentage() {
    var percentage = this.dataEntryForm.get('de4').get('performanceDecreasePercentage');
    return percentage.hasError('pattern') ? 'Only between 0 and 100' :
      percentage.hasError('min') ? 'The number must be in the range 0 or 100' :
        percentage.hasError('max') ? 'The number must be in the range 0 or 100' : '';
  }

  quitFormGroup(dataEntryForm) {
    var form = new Object();

    if (dataEntryForm["de1"]) {
      form = Object.assign(form, dataEntryForm["de1"])
    }

    if (dataEntryForm["de2"]) {
      form = Object.assign(form, dataEntryForm["de2"])
    }

    if (dataEntryForm["de3"]) {
      form = Object.assign(form, dataEntryForm["de3"])
    }

    if (dataEntryForm["de4"]) {
      form = Object.assign(form, {performanceDecreaseList: dataEntryForm["de4"]})
    }

    if (dataEntryForm["de5"]) {
      form = Object.assign(form, dataEntryForm["de5"])
    }

    if (dataEntryForm["de6"]) {
      form = Object.assign(form, dataEntryForm["de6"])
    }

    if (dataEntryForm["de7"]) {
      form = Object.assign(form, dataEntryForm["de7"])
    }

    if (dataEntryForm["de8"]) {
      form = Object.assign(form, dataEntryForm["de8"])
    }

    if (dataEntryForm["de9"]) {
      form = Object.assign(form, dataEntryForm["de9"])
    }

    if (dataEntryForm["de10"]) {
      form = Object.assign(form, dataEntryForm["de10"])
    }

    if (dataEntryForm["deMOD"]) {
      dataEntryForm["deMOD"]["occupationalDisabilityEnd"] = dataEntryForm["deMOD"]["occupationalDisabilityStart"]
      form = Object.assign(form, dataEntryForm["deMOD"]);
    }

    if (dataEntryForm["deUnk"]) {
      form = Object.assign(form, dataEntryForm["deUnk"])
    }

    return form;
  }

  addNewPerformanceDecrease() {
    const control = <FormArray>this.dataEntryForm.get('de4');
    control.push(this.fb.group(this.performanceDecreaseGroup));
  }

  deletePerformanceDecrease(index: number) {    
    if (this.dataEntryForm.get('de4').value.length > 1) {
      const control = <FormArray>this.dataEntryForm.get('de4');
      control.removeAt(index);
    }
  }

  fillTheForm() {

    //We put the DE1 info
    if (this.showDE1) {
      const de1 = this.dataEntryForm.get('de1');

      de1.get('patientFirstName').setValue(this.certimedModel.patientFirstName);
      de1.get('patientLastName').setValue(this.certimedModel.patientLastName);
      de1.get('nationalNumber').setValue(this.certimedModel.nationalNumber);
      de1.get('personnelNumber').setValue(this.certimedModel.personnelNumber);

      de1.updateValueAndValidity();
    }

    //We put the DE2 info
    if (this.showDE2) {
      const de2 = this.dataEntryForm.get('de2');

      de2.get('allowLeavingHome').setValue(this.certimedModel.allowLeavingHome);
      de2.get('incapacityReason').setValue(this.certimedModel.incapacityReason);
      de2.get('hospitalizationStart').setValue(this.certimedModel.hospitalizationStart);
      de2.get('hospitalizationEnd').setValue(this.certimedModel.hospitalizationEnd);

      de2.get('occupationalDisabilityStart').setValue(this.certimedModel.occupationalDisabilityStart);
      de2.get('occupationalDisabilityEnd').setValue(this.certimedModel.occupationalDisabilityEnd);
      de2.get('certificateType').setValue(this.certimedModel.certificateType);
      de2.get('certificateDate').setValue(this.certimedModel.certificateDate);

      de2.get('signature').setValue(this.certimedModel.signature);
      de2.get('stamp').setValue(this.certimedModel.stamp);
      de2.get('doctorName').setValue(this.certimedModel.doctorName);
      de2.get('doctorPhoneNumber').setValue(this.certimedModel.doctorPhoneNumber);

      de2.get('rizivNumber').setValue(this.certimedModel.rizivNumber);
      de2.get('travelAbroadStart').setValue(this.certimedModel.travelAbroadStart);
      de2.get('travelAbroadEnd').setValue(this.certimedModel.travelAbroadEnd);

      de2.updateValueAndValidity();
    }

    //We put the DE3 info
    if (this.showDE3) {
      const de3 = this.dataEntryForm.get('de3');

      de3.get('patientStreet').setValue(this.certimedModel.patientStreet);
      de3.get('patientHouseNumber').setValue(this.certimedModel.patientHouseNumber);
      de3.get('patientBusNumber').setValue(this.certimedModel.patientBusNumber);
      de3.get('patientPostalNumber').setValue(this.certimedModel.patientPostalNumber);
      de3.get('patientCity').setValue(this.certimedModel.patientCity);
      de3.get('patientCountryCode').setValue(this.certimedModel.patientCountryCode);

      de3.updateValueAndValidity();
    }

    //We put the DE4 info
    if (this.showDE4) {
      const de4 = this.dataEntryForm.get('de4') as FormArray;

      if (this.certimedModel.performanceDecreaseList != null) {
        this.certimedModel.performanceDecreaseList.forEach((element: PerformaceDecrease, index: number) => {
          // Skip push first one as it is already in the form
          if (index > 0) de4.push(this.fb.group(this.performanceDecreaseGroup));
          de4.at(index).patchValue(element);
        });
      }

      de4.updateValueAndValidity();
    }

    //We put the DE5 info
    if (this.showDE5) {
      const de5 = this.dataEntryForm.get('de5');

      de5.get('companyName').setValue(this.certimedModel.companyName);
      de5.get('companyMensuraId').setValue(this.certimedModel.companyMensuraId);
      de5.get('resultDoctorId').setValue(this.certimedModel.resultDoctorId);
      de5.get('resultDoctorFirstName').setValue(this.certimedModel.resultDoctorFirstName);
      de5.get('resultDoctorLastName').setValue(this.certimedModel.resultDoctorLastName);
      de5.get('resultCheck').setValue(this.certimedModel.resultCheck);
      de5.get('resultDate').setValue(this.certimedModel.resultDate);
      de5.get('resultTime').setValue(this.certimedModel.resultTime);

      de5.updateValueAndValidity();
    }

    //We put the DE6 info
    if (this.showDE6) {
      const de6 = this.dataEntryForm.get('de6');

      de6.get('temporaryResidenceStart').setValue(this.certimedModel.temporaryResidenceStart);
      de6.get('temporaryResidenceEnd').setValue(this.certimedModel.temporaryResidenceEnd);
      de6.get('temporaryResidenceName').setValue(this.certimedModel.temporaryResidenceName);
      de6.get('temporaryResidenceStreet').setValue(this.certimedModel.temporaryResidenceStreet);
      de6.get('temporaryResidenceHouseNumber').setValue(this.certimedModel.temporaryResidenceHouseNumber);
      de6.get('temporaryResidenceBusNumber').setValue(this.certimedModel.temporaryResidenceBusNumber);
      de6.get('temporaryResidencePostalNumber').setValue(this.certimedModel.temporaryResidencePostalNumber);
      de6.get('temporaryResidenceCity').setValue(this.certimedModel.temporaryResidenceCity);
      de6.get('temporaryResidenceCountryCode').setValue(this.certimedModel.temporaryResidenceCountryCode);

      de6.updateValueAndValidity();
    }

    //We put the DE7 info
    if (this.showDE7) {
      const de7 = this.dataEntryForm.get('de7');

      de7.get('companyName').setValue(this.certimedModel.companyName);

      de7.updateValueAndValidity();
    }

    //We put the DE8 info
    if (this.showDE8) {
      const de8 = this.dataEntryForm.get('de8');

      if (this.certimedModel.diagnose) {
        de8.get('diagnose').setValue(this.certimedModel.diagnose);
        de8.updateValueAndValidity();
      }
    }

    //We put the DE9 info
    if (this.showDE9) {
      const de9 = this.dataEntryForm.get('de9');

      de9.get('certificatePostmarkdate').setValue(this.certimedModel.certificatePostmarkdate);

      de9.updateValueAndValidity();
    }

    if (this.showDE10) {
      const de10 = this.dataEntryForm.get('de10');

      de10.get('homePhoneNumber').setValue(this.certimedModel.homePhoneNumber);
      de10.get('mobilePhoneNumber').setValue(this.certimedModel.mobilePhoneNumber);
      de10.get('senderEmail').setValue(this.certimedModel.senderEmail);

      de10.updateValueAndValidity();
    }

    //We put the DEUnk info
    if (this.showUnknown) {
      const deUnk = this.dataEntryForm.get('deUnk');

      deUnk.get('documentType').setValue(this.certimedModel.documentType);

      deUnk.updateValueAndValidity();
    }

    //We put the DEMOD info
    if (this.showMensuraOneDay) {
      const deMOD = this.dataEntryForm.get('deMOD');

      deMOD.get('companyMensuraId').setValue(this.certimedModel.companyMensuraId);
      deMOD.get('personnelNumber').setValue(this.certimedModel.personnelNumber);
      deMOD.get('occupationalDisabilityStart').setValue(this.certimedModel.occupationalDisabilityStart);
      deMOD.get('occupationalDisabilityEnd').setValue(this.certimedModel.occupationalDisabilityEnd);

      deMOD.updateValueAndValidity();
    }
  }

  areEqualValidator(value: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (value == '1') {
        return { 'areEquals': true };
      }
      return null;
    };
  }

  updateValidations() {

    //Update DE1 validations
    if (this.showDE1) {
      const de1 = this.dataEntryForm.get('de1');

      de1.get('patientFirstName').setValidators([de1.get('patientFirstName').validator, this.areEqualValidator(this.certimedDCInfo.de1wrong[0])]);
      de1.get('patientLastName').setValidators([de1.get('patientLastName').validator, this.areEqualValidator(this.certimedDCInfo.de1wrong[1])]);
      de1.get('nationalNumber').setValidators(this.areEqualValidator(this.certimedDCInfo.de1wrong[2]));
      de1.get('personnelNumber').setValidators(this.areEqualValidator(this.certimedDCInfo.de1wrong[3]));

      de1.updateValueAndValidity();
    }

    //Update DE2 validations
    if (this.showDE2) {
      const de2 = this.dataEntryForm.get('de2');

      de2.get('allowLeavingHome').setValidators([de2.get('allowLeavingHome').validator, this.areEqualValidator(this.certimedDCInfo.de2wrong[0])]);
      de2.get('incapacityReason').setValidators([de2.get('incapacityReason').validator, this.areEqualValidator(this.certimedDCInfo.de2wrong[1])]);
      de2.get('hospitalizationStart').setValidators([de2.get('hospitalizationStart').validator, this.areEqualValidator(this.certimedDCInfo.de2wrong[2])]);
      de2.get('hospitalizationEnd').setValidators([de2.get('hospitalizationEnd').validator, this.areEqualValidator(this.certimedDCInfo.de2wrong[3])]);

      de2.get('occupationalDisabilityStart').setValidators([de2.get('occupationalDisabilityStart').validator, this.areEqualValidator(this.certimedDCInfo.de2wrong[4])]);
      de2.get('occupationalDisabilityEnd').setValidators([de2.get('occupationalDisabilityEnd').validator, this.areEqualValidator(this.certimedDCInfo.de2wrong[5])]);
      de2.get('certificateType').setValidators([de2.get('certificateType').validator, this.areEqualValidator(this.certimedDCInfo.de2wrong[6])]);
      de2.get('certificateDate').setValidators([de2.get('certificateDate').validator, this.areEqualValidator(this.certimedDCInfo.de2wrong[7])]);

      de2.get('signature').setValidators([de2.get('signature').validator, this.areEqualValidator(this.certimedDCInfo.de2wrong[8])]);
      de2.get('stamp').setValidators([de2.get('stamp').validator, this.areEqualValidator(this.certimedDCInfo.de2wrong[9])]);
      de2.get('doctorName').setValidators(this.areEqualValidator(this.certimedDCInfo.de2wrong[10]));
      de2.get('doctorPhoneNumber').setValidators(this.areEqualValidator(this.certimedDCInfo.de2wrong[11]));

      de2.get('rizivNumber').setValidators(this.areEqualValidator(this.certimedDCInfo.de2wrong[12]));
      de2.get('travelAbroadStart').setValidators([de2.get('travelAbroadStart').validator, this.areEqualValidator(this.certimedDCInfo.de2wrong[13])]);
      de2.get('travelAbroadEnd').setValidators([de2.get('travelAbroadEnd').validator, this.areEqualValidator(this.certimedDCInfo.de2wrong[14])]);

      de2.updateValueAndValidity();
    }

    //Update DE3 validations
    if (this.showDE3) {
      const de3 = this.dataEntryForm.get('de3');

      de3.get('patientStreet').setValidators([de3.get('patientStreet').validator, this.areEqualValidator(this.certimedDCInfo.de3wrong[0])]);
      de3.get('patientHouseNumber').setValidators([de3.get('patientHouseNumber').validator, this.areEqualValidator(this.certimedDCInfo.de3wrong[1])]);
      de3.get('patientBusNumber').setValidators(this.areEqualValidator(this.certimedDCInfo.de3wrong[2]));
      de3.get('patientPostalNumber').setValidators(this.areEqualValidator(this.certimedDCInfo.de3wrong[3]));
      de3.get('patientCity').setValidators([de3.get('patientCity').validator, this.areEqualValidator(this.certimedDCInfo.de3wrong[4])]);
      de3.get('patientCountryCode').setValidators(this.areEqualValidator(this.certimedDCInfo.de3wrong[5]));

      de3.updateValueAndValidity();
    }

    //Update DE4 validations
    if (this.showDE4) {
      const de4 = this.dataEntryForm.get('de4') as FormArray;

      de4.controls.forEach((element: FormGroup, index: number) => {  
        Object.values(element.controls).forEach((control: AbstractControl, index2: number) => {
          
          let wrongValue;

          try {
            wrongValue = this.certimedDCInfo.de4wrong[index][index2];
          } catch (error) {
            wrongValue = '1';
          }
          control.setValidators([control.validator, this.areEqualValidator(wrongValue)]);
          control.markAsTouched();
          control.updateValueAndValidity();
        });
      });
    }

    //Update DE5 validations
    if (this.showDE5) {
      const de5 = this.dataEntryForm.get('de5');

      de5.get('companyName').setValidators([de5.get('companyName').validator, this.areEqualValidator(this.certimedDCInfo.de5wrong[0])]);
      de5.get('companyMensuraId').setValidators(this.areEqualValidator(this.certimedDCInfo.de5wrong[1]));
      de5.get('resultDoctorId').setValidators(this.areEqualValidator(this.certimedDCInfo.de5wrong[2]));
      de5.get('resultDoctorFirstName').setValidators(this.areEqualValidator(this.certimedDCInfo.de5wrong[3]));
      de5.get('resultDoctorLastName').setValidators(this.areEqualValidator(this.certimedDCInfo.de5wrong[4]));
      de5.get('resultCheck').setValidators([de5.get('resultCheck').validator, this.areEqualValidator(this.certimedDCInfo.de5wrong[5])]);
      de5.get('resultDate').setValidators([de5.get('resultDate').validator, this.areEqualValidator(this.certimedDCInfo.de5wrong[6])]);
      de5.get('resultTime').setValidators([de5.get('resultTime').validator, this.areEqualValidator(this.certimedDCInfo.de5wrong[7])]);

      de5.updateValueAndValidity();
    }

    //Update DE6 validations
    if (this.showDE6) {
      const de6 = this.dataEntryForm.get('de6');

      de6.get('temporaryResidenceStart').setValidators([de6.get('temporaryResidenceStart').validator, this.areEqualValidator(this.certimedDCInfo.de6wrong[0])]);
      de6.get('temporaryResidenceEnd').setValidators([de6.get('temporaryResidenceEnd').validator, this.areEqualValidator(this.certimedDCInfo.de6wrong[1])]);
      de6.get('temporaryResidenceName').setValidators(this.areEqualValidator(this.certimedDCInfo.de6wrong[2]));
      de6.get('temporaryResidenceStreet').setValidators(this.areEqualValidator(this.certimedDCInfo.de6wrong[3]));
      de6.get('temporaryResidenceHouseNumber').setValidators(this.areEqualValidator(this.certimedDCInfo.de6wrong[4]));
      de6.get('temporaryResidenceBusNumber').setValidators(this.areEqualValidator(this.certimedDCInfo.de6wrong[5]));
      de6.get('temporaryResidencePostalNumber').setValidators(this.areEqualValidator(this.certimedDCInfo.de6wrong[6]));
      de6.get('temporaryResidenceCity').setValidators(this.areEqualValidator(this.certimedDCInfo.de6wrong[7]));
      de6.get('temporaryResidenceCountryCode').setValidators([de6.get('temporaryResidenceCountryCode').validator, this.areEqualValidator(this.certimedDCInfo.de6wrong[8])]);

      de6.updateValueAndValidity();
    }

    if (this.showDE8) {
      const de8 = this.dataEntryForm.get('de8');

      de8.get('diagnose').setValidators([Validators.required, ValueInListValidator(), this.areEqualValidator(this.certimedDCInfo.de8wrong[0])]);

      de8.updateValueAndValidity();
    }

    if (this.showDE9) {
      const de9 = this.dataEntryForm.get('de9');

      de9.get('certificatePostmarkdate').setValidators(this.areEqualValidator(this.certimedDCInfo.de9wrong[0]));

      de9.updateValueAndValidity();
    }

    if (this.showDE10) {
      const de10 = this.dataEntryForm.get('de10');

      de10.get('homePhoneNumber').setValidators(this.areEqualValidator(this.certimedDCInfo.de10wrong[0]));
      de10.get('mobilePhoneNumber').setValidators(this.areEqualValidator(this.certimedDCInfo.de10wrong[1]));
      de10.get('senderEmail').setValidators(this.areEqualValidator(this.certimedDCInfo.de10wrong[2]));

      de10.updateValueAndValidity();
    }

    if (this.showMensuraOneDay) {
      const deMOD = this.dataEntryForm.get('deMOD');

      deMOD.get('companyMensuraId').setValidators([deMOD.get('companyMensuraId').validator]);
      deMOD.get('personnelNumber').setValidators([deMOD.get('personnelNumber').validator]);
      deMOD.get('occupationalDisabilityStart').setValidators([deMOD.get('occupationalDisabilityStart').validator]);
      deMOD.get('occupationalDisabilityEnd').setValidators([deMOD.get('occupationalDisabilityEnd').validator]);

      deMOD.updateValueAndValidity();
    }

    if (this.showUnknown) {
      const deUnk = this.dataEntryForm.get('deUnk');

      deUnk.get('documentType').setValidators([deUnk.get('documentType').validator]);

      deUnk.updateValueAndValidity();
    }
  }

  openDialogSearch(event: PointerEvent): void {
    const dialogPosition: DialogPosition = {
      top: '50%',
      left: '5%'
    };

    const dialogRef = this.dialog.open(CertimedDataEntrySearchModalComponent, {
      data: {'allowLeavingHome': this.dataEntryForm.get("de2").get("allowLeavingHome").value, 'commonInfo': this.commonInfo},
      position: dialogPosition,
      viewContainerRef: this.ref,
      autoFocus: false,
    });

    dialogRef.afterOpened().subscribe(x => {

      var divOverlay = (document.getElementsByClassName("cdk-global-overlay-wrapper").item(0) as HTMLElement)
      divOverlay.removeAttribute("class");
      divOverlay.classList.add("classOverlay");

      //remove the current parent.
      var dialog = document.getElementsByClassName("cdk-overlay-container").item(0) as HTMLElement;
      dialog.parentElement.removeChild(dialog);


      //Put the child in hte correct site.
      var parentConflict = document.getElementsByClassName("app-certimed-data-conflict-viewer")
      if (parentConflict.length <= 0) {
        var parent = (document.getElementsByTagName("app-certimed-data-entry-viewer").item(0) as HTMLElement);
        parent.append(dialog);
      }
      else {
        var parent = (document.getElementsByTagName("app-certimed-data-conflict-viewer").item(0) as HTMLElement);
        parent.append(dialog);
      }

    })

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          console.log(result);
          this.isSearchDone = true;
          this.showESResult = true;

          if (result["doctorPhoneNumber"] == "Not Found") {
            this.resultFound = false;
          }
          else {
            this.resultFound = true;
            //Set the values.
            this.dataEntryForm.get("de2").get("doctorName").setValue(result["doctorName"]);
            this.dataEntryForm.get("de2").get("doctorPhoneNumber").setValue(result["doctorPhoneNumber"]);
            this.dataEntryForm.get("de2").get("rizivNumber").setValue(result["rizivNumber"]);
          }

        }
        //We put the notification in the correct site.
        var notification = document.getElementsByClassName("cdk-overlay-container").item(0) as HTMLElement;
        notification.parentElement.removeChild(notification);

        var parent = document.getElementsByTagName("body").item(0) as HTMLElement;
        parent.append(notification);

      },
      error => {
        console.log('The dialog was closed ERROR ' + error);
      }
    );
  }

  openDialogMensuraSearch(): void {

    const dialogRef = this.dialog.open(CertimedMensuraSearchModalComponent, {
      data: {'commonInfo': this.commonInfo},//this.dataEntryForm.get("de2").get("allowLeavingHome").value,
      position: { top: '5px' },
      viewContainerRef: this.ref,
      autoFocus: false

    });

    dialogRef.afterOpened().subscribe(x => {

      var divOverlay = (document.getElementsByClassName("cdk-global-overlay-wrapper").item(0) as HTMLElement)
      divOverlay.removeAttribute("class");
      divOverlay.classList.add("classOverlay");

      //remove the current parent.
      var dialog = document.getElementsByClassName("cdk-overlay-container").item(0) as HTMLElement;
      dialog.parentElement.removeChild(dialog);


      //Put the child in hte correct site.
      var parentConflict = document.getElementsByClassName("app-certimed-data-conflict-viewer")
      if (parentConflict.length <= 0) {
        var parent = (document.getElementsByTagName("app-certimed-data-entry-viewer").item(0) as HTMLElement);
        parent.append(dialog);
      }
      else {
        var parent = (document.getElementsByTagName("app-certimed-data-conflict-viewer").item(0) as HTMLElement);
        parent.append(dialog);
      }

    })

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          console.log(result);
          this.isSearchDone = true;
          this.showESResult = true;

          if (result["personeelNo"] == "Not Found") {
            this.resultFound = false;
          }
          else {
            this.resultFound = true;
            //Set the values.
            this.dataEntryForm.get("deMOD").get("companyMensuraId").setValue(result["filiaalNummer"]);
            this.dataEntryForm.get("deMOD").get("personnelNumber").setValue(result["personeelNo"]);

          }

        }
        //We put the notification in the correct site.
        var notification = document.getElementsByClassName("cdk-overlay-container").item(0) as HTMLElement;
        notification.parentElement.removeChild(notification);

        var parent = document.getElementsByTagName("body").item(0) as HTMLElement;
        parent.append(notification);

      },
      error => {
        console.log('The dialog was closed ERROR ' + error);
      }
    );
  }

  async close() {
    this.disableClose = true;
    this.disableSend = true;
    this.disableBlock = true;
    var response = await this.commonControllerService.UnlockUser(this.folderHash);
    this.notification.open(response, "", {
      duration: 2000,
      panelClass: "centeredNotification"

    }).afterDismissed().toPromise().then((response) => { self.close(); this.disableClose = false; this.disableSend = false; this.disableBlock = false; });
    
  }

  getErrorSearchMessage(field: AbstractControl) {
    return field.hasError('required') ? 'You need to search a value' : '';
  }

  getErrorFirmPatientIDValidation(result: AbstractControl) {
    return result.hasError('pattern') ? 'Only 11 digits' :
      result.hasError('minlength') ? 'The length must be 11 digits' :
        result.hasError('maxlength') ? 'The length must be 11 digits' : '';
  }

  async fillDoctorInfo() {
    if (!this.isFilled) {
      var doctorInfo = await this.dataEntryControllerService.GetDoctorInfo(this.folderHash);
      console.log(doctorInfo);

      if (this.showDE5) {
        
        if (doctorInfo["resultDoctorId"])
          this.dataEntryForm.get("de5").get("resultDoctorId").setValue(doctorInfo["resultDoctorId"]);

        if (doctorInfo["resultDoctorFirstName"])
          this.dataEntryForm.get("de5").get("resultDoctorFirstName").setValue(doctorInfo["resultDoctorFirstName"]);

        if (doctorInfo["resultDoctorLastName"])
          this.dataEntryForm.get("de5").get("resultDoctorLastName").setValue(doctorInfo["resultDoctorLastName"]);
      }

      if (this.showMensuraOneDay) {
        if (doctorInfo["companyMensuraId"])
          this.dataEntryForm.get('deMOD').get('companyMensuraId').setValue(doctorInfo["companyMensuraId"]);

        if (doctorInfo["personnelNumber"])
          this.dataEntryForm.get('deMOD').get('personnelNumber').setValue(doctorInfo["personnelNumber"]);

        if (doctorInfo["occupationalDisabilityStart"]) {
          var splitDate = (doctorInfo["occupationalDisabilityStart"] as String).split('-');
          this.dataEntryForm.get('deMOD').get('occupationalDisabilityStart').setValue(splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0]);
        }
      }
    }
  }

  isCorrectField(validField: AbstractControl): Boolean {

    if (this.isDCView && this.dataEntryInfo.key != "de3" && this.isFilled && validField.valid) {
      var value = validField.value as String;
      if (value != null && value.trim() != "")
        return true;
      else
        return false;
    }
    else
      return false;
  }

  block() {

    this.disableSend = true;
    this.disableClose = true;
    this.disableBlock = true;
    this.disableSave.emit(false);

    const dialogRef = this.dialog.open(BlockModalComponent, {
      position: { top: '5px' },
      viewContainerRef: this.ref,
      autoFocus: false,
      data: "Data entry"
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          console.log(result);

          if (result["reason"] != null && result["reason"] != undefined && result["remarks"] != null && result["remarks"] != undefined) {
            this.commonControllerService.SendBlock(this.folderHash, this.user, "CERTIMED", result, "DATA_ENTRY").then(response => {
              this.notification.open(response, "", {
                duration: 2000,
                panelClass: "centeredNotification"

              }).afterDismissed().toPromise().then(x => {
                if (response == "The DataEntry has been blocked successfully") {
                  this.close();
                }
                else {
                  this.disableSend = false;
                  this.disableClose = false;
                  this.disableBlock = false;
                  this.disableSave.emit(false);
                }
              });
            });
          }
          else {
            this.disableSend = false;
            this.disableClose = false;
            this.disableBlock = false;
            this.disableSave.emit(false);
          }
        }
      },
      error => {
        console.log('The dialog was closed ERROR ' + error);
      }
    );

    dialogRef.backdropClick().subscribe(result => {
      this.disableSend = false;
      this.disableClose = false;
      this.disableBlock = false;
      this.disableSave.emit(false);
    })

    dialogRef.keydownEvents().subscribe(keyboardEvent => {
      if (keyboardEvent.code == "Escape") {
        this.disableBlock = false;
        this.disableClose = false;
        this.disableSend = false;
        this.disableSave.emit(false);
      }
    })
  }

  static validateResultDate(date: AbstractControl): ValidationErrors {
    if (date.value && date.value != null && date.value != "") {
      var today = new Date();
      //We quit the hours.
      today = new Date((today.getMonth() + 1).toString() + "/" + (today.getDate()).toString() + "/" + today.getFullYear().toString());
      var dateSplitted = (date.value as string).split('/');
      var resultDate = new Date(dateSplitted[1] + "/" + dateSplitted[0] + "/" + dateSplitted[2]);
      if (today < resultDate) {
        return { 'validateResultDate': true };
      }
      else {
        return null;
      }
    }
    else
      return null;

  }

  getErrorResultDate(resultDate: AbstractControl) {
    return resultDate.hasError('validateResultDate') ? 'The result date should be less or equal than the current Date.' : '';
  }

  static validateOccupationalDisabilityStart(date: AbstractControl): ValidationErrors {
    if (date.value && date.value != null && date.value != "") {
      var today = new Date();
      //We quit the hours.
      today = new Date((today.getMonth() + 1).toString() + "/" + (today.getDate()).toString() + "/" + today.getFullYear().toString());
      //We put the limit date (one week in the future)
      today.setDate(today.getDate() + 7);

      var dateSplitted = (date.value as string).split('/');
      var occupationalDisabilityStartDate = new Date(dateSplitted[1] + "/" + dateSplitted[0] + "/" + dateSplitted[2]);
      //It should be before one week in the future
      if (today < occupationalDisabilityStartDate) {
        return { 'validateOccupationalDisabilityStart': true };
      }
      else {
        return null;
      }
    }
    else
      return null;

  }

  getErrorOccupationalDisabilityStart(occupationalDisabilityStart: AbstractControl) {
    return occupationalDisabilityStart.hasError('validateOccupationalDisabilityStart') ? 'The date should be less or equal than the next week.' : '';
  }  

  transformValueSelect(dataEntryForm) {
    console.log(dataEntryForm)
    if (dataEntryForm['allowLeavingHome']) {
      var value = this.verlatenVanDeWoningOptions.find(option => option.toUpperCase() == (dataEntryForm['allowLeavingHome']).toUpperCase());
      dataEntryForm['allowLeavingHome'] = value;
    }

    if (dataEntryForm['incapacityReason']) {
      var value = this.redenArbeidsongeschiktheidOptions.find(option => option.toUpperCase() == dataEntryForm['incapacityReason'].toUpperCase());
      dataEntryForm['incapacityReason'] = value;
    }

    if (dataEntryForm['certificateType']) {
      var value = this.attestTypeOptions.find(option => option.toUpperCase() == dataEntryForm['certificateType'].toUpperCase());
      dataEntryForm['certificateType'] = value;
    }

    if (dataEntryForm['signature']) {
      if (dataEntryForm['signature'].toUpperCase() == "YES")
        dataEntryForm['signature'] = "Yes";
      else if (dataEntryForm['signature'].toUpperCase() == "NO")
        dataEntryForm['signature'] = "No";
    }

    if (dataEntryForm['stamp']) {
      if (dataEntryForm['stamp'].toUpperCase() == "YES")
        dataEntryForm['stamp'] = "Yes";
      else if (dataEntryForm['stamp'].toUpperCase() == "NO")
        dataEntryForm['stamp'] = "No";
    }

    if (dataEntryForm['patientCountryCode']) {
      var value = this.verblijfplaatsLandcodeOptions.find(option => option.toUpperCase() == dataEntryForm['patientCountryCode'].toUpperCase());
      dataEntryForm['patientCountryCode'] = value;
    }

    if (dataEntryForm['companyName']) {
      var value = this.firmanaamOptions.find(option => option.toUpperCase() == dataEntryForm['companyName'].toUpperCase());
      if(value)
        dataEntryForm['companyName'] = value;
    }

    if (dataEntryForm['resultCheck']) {
      var value = this.resultaatControleOptions.find(option => option.toUpperCase() == dataEntryForm['resultCheck'].toUpperCase());
      dataEntryForm['resultCheck'] = value;
    }

    if (dataEntryForm['temporaryResidenceCountryCode']) {
      var value = this.verblijfplaatsLandcodeOptions.find(option => option.toUpperCase() == dataEntryForm['temporaryResidenceCountryCode'].toUpperCase());
      dataEntryForm['temporaryResidenceCountryCode'] = value;
    }

    if (dataEntryForm['documentType']) {
      var value = this.documentTypeOptions.find(option => option.toUpperCase() == dataEntryForm['documentType'].toUpperCase());
      dataEntryForm['documentType'] = value;
    }

    if (dataEntryForm['diagnose']) {
      var value = (dataEntryForm['diagnose'] as string).split(',')[0].trim();
      dataEntryForm['diagnose'] = value;
    }
  }

  validateSearchDC() {
    if (this.showDE2) {
      var doctorName = this.dataEntryForm.get("de2").get("doctorName").value;
      var doctorPhoneNumber = this.dataEntryForm.get("de2").get("doctorPhoneNumber").value;
      var doctorRizivNumber = this.dataEntryForm.get("de2").get("rizivNumber").value;

      if ((doctorName && doctorName != "") || (doctorPhoneNumber && doctorPhoneNumber != "") || (doctorRizivNumber && doctorRizivNumber != ""))
        return true;
      else
        return false;
    }
    return false;
  }

  validationStartDatesEndDates(startDate: AbstractControl, endDate: AbstractControl) {
    var isStartDateEmpty = !(startDate.value && startDate.value != null && startDate.value != "");
    var isEndDateEmpty = !(endDate.value && endDate.value != null && endDate.value != "");

    //If we only have one of both fields filled, the validation is wrong.
    if ((isEndDateEmpty && !isStartDateEmpty) || (!isEndDateEmpty && isStartDateEmpty)) {
      return "You need to writte both fields. (startDate and endDate)"
    }
    else if (!isEndDateEmpty && !isStartDateEmpty) { //The end date needs to be later than start date
      var startDateSplitted = (startDate.value as string).split('/');
      var endDateSplitted = (endDate.value as string).split('/');
      var start = new Date(startDateSplitted[1] + "/" + startDateSplitted[0] + "/" + startDateSplitted[2]);
      var end = new Date(endDateSplitted[1] + "/" + endDateSplitted[0] + "/" + endDateSplitted[2]);

      if (start > end)
        return "The end time should be later or equal than start time";
      else
        return "";

    }
    else { //If both are empty, the validation is correct.
      return "";
    }
  }

  datesAreValid() {
    var areValid = true;

    if (this.showDE2) {
      this.errorHospitalizationDateMessage = this.validationStartDatesEndDates(this.dataEntryForm.get('de2').get('hospitalizationStart'), this.dataEntryForm.get('de2').get('hospitalizationEnd'));
      this.errorOccupationalDisabilityDateMessage = this.validationStartDatesEndDates(this.dataEntryForm.get('de2').get('occupationalDisabilityStart'), this.dataEntryForm.get('de2').get('occupationalDisabilityEnd'));
      this.errorTravelAbroadDateMessage = this.validationStartDatesEndDates(this.dataEntryForm.get('de2').get('travelAbroadStart'), this.dataEntryForm.get('de2').get('travelAbroadEnd'));

      this.showErrorHospitalizationDate = this.errorHospitalizationDateMessage != "";
      this.showErrorOccupationalDisabilityDate = this.errorOccupationalDisabilityDateMessage != "";
      this.showErrorTravelAbroadDate = this.errorTravelAbroadDateMessage != "";

      if (this.showErrorHospitalizationDate || this.showErrorOccupationalDisabilityDate || this.showErrorTravelAbroadDate) {
        areValid = false;
      }
    }

    if (this.showDE4) {
      const de4 = this.dataEntryForm.get('de4') as FormArray;

      this.errorPerformanceDecreaseDateMessage = de4.controls
        .map((element: FormGroup) =>
          this.validationStartDatesEndDates(
            element.get("performanceDecreaseStart"),
            element.get("performanceDecreaseEnd")
          )
        )
        .reduce((acc, message) => {
          if (message != "") {
            acc = message;
          }
          return acc;
        }, "");

      // this.errorPerformanceDecreaseDateMessage = this.validationStartDatesEndDates(this.dataEntryForm.get('de4').get('performanceDecreaseStart'), this.dataEntryForm.get('de4').get('performanceDecreaseEnd'));
      this.showErrorPerformanceDecreaseDate = this.errorPerformanceDecreaseDateMessage != "";

      if (this.showErrorPerformanceDecreaseDate)
        areValid = false;
    }

    if (this.showDE6) {
      this.errorTemporaryResidenceDateMessage = this.validationStartDatesEndDates(this.dataEntryForm.get('de6').get('temporaryResidenceStart'), this.dataEntryForm.get('de6').get('temporaryResidenceEnd'));
      this.showErrorTemporaryResidenceDate = this.errorTemporaryResidenceDateMessage != "";

      if (this.showErrorTemporaryResidenceDate)
        areValid = false;
    }

    return areValid;
  }

  

}
