import { Component, OnInit, Inject } from '@angular/core';
import { certimedMensuraSearchInfo } from '../Interfaces/certimedDCInfo';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CertimedDataEntrySearchModalComponent } from '../certimed-data-entry-search-modal/certimed-data-entry-search-modal.component';
import { DataEntryControllerService } from '../services/data-entry-controller.service';

@Component({
  selector: 'app-certimed-mensura-search-modal',
  templateUrl: './certimed-mensura-search-modal.component.html',
  styleUrls: ['./certimed-mensura-search-modal.component.css']
})
export class CertimedMensuraSearchModalComponent implements OnInit {

  folderHash: string;

  displayedColumns: string[] = ['voornaam', 'naam', 'personeelNo', 'filiaalNummer', 'rijks', 'mensuraId'];
  dataSource = new Array<certimedMensuraSearchInfo>();
  showResult: boolean = false;
  totalResult: number = 0;
  totalRows: number = 0;

  search = new FormGroup({
    voornaam: new FormControl(''),
    naam: new FormControl(''),
    general: new FormControl(''),
  })

  constructor(public dialogRef: MatDialogRef<CertimedDataEntrySearchModalComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private dataEntryControllerService: DataEntryControllerService) { }


  doubleClick(value: certimedMensuraSearchInfo) {
    this.dialogRef.close(value);
  }

  ngOnInit() {
    this.folderHash = this.data.commonInfo.folderHash;
  }

  async onSubmit(resultForm) {

    console.log(resultForm);
    this.showResult = true;
    if (resultForm["voornaam"] == "" && resultForm["naam"] == "" && resultForm["general"] == "") {
      this.dataSource = new Array<certimedMensuraSearchInfo>({ voornaam: "", naam: "", personeelNo: "Not Found", filiaalNummer: "", rijks: "", mensuraId: "" });
    }
    else {
      var result = await this.dataEntryControllerService.Search(resultForm, this.folderHash, "MENSURA");
      this.dataSource = result["result"];
      this.totalResult = result["totalResult"];
      this.totalRows = this.dataSource.length;
      if (this.dataSource.length == 0) {
        this.dataSource.push({ voornaam: "", naam: "", personeelNo: "Not Found", filiaalNummer: "", rijks: "", mensuraId: "" });
      }
    }
  }
}
