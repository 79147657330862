import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-block-modal',
  templateUrl: './block-modal.component.html',
  styleUrls: ['./block-modal.component.css']
})
export class BlockModalComponent implements OnInit {

  block = new FormGroup({
    reason: new FormControl(''),
    remarks: new FormControl(''),
  });


  constructor(public dialogRef: MatDialogRef<BlockModalComponent>, @Inject(MAT_DIALOG_DATA) public process ) { }
  ngOnInit() {

  }

  async onSubmit(resultBlock) {
    this.dialogRef.close(resultBlock);
  }

}
