import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "../auth/services/auth.service";
import { CommonInfoFolder } from "../Interfaces/commonInfoFolder";
import { CommonControllerService } from "../services/common-controller.service";

@Component({
  selector: "app-start",
  templateUrl: "./start.component.html",
  styleUrls: ["./start.component.css"],
})
export class StartComponent implements OnInit {
  folderHash: string;
  username: string = "";
  heightWindow: string;
  startDateTime: Date;
  commonInfo: CommonInfoFolder;

  showTraduction: boolean = false;
  isloaded: boolean = false;

  pathname: string = "";
  validURL: boolean = true;
  url: URL;

  shouldDisplayDE: boolean = false;
  shouldDisplayDC: boolean = false;
  shouldDisplayCR: boolean = false;

  constructor(
    private commonControllerService: CommonControllerService,
    private translate: TranslateService,
    private authService: AuthService,
    private cookieService: CookieService
  ) {
    translate.setDefaultLang("nl");

    this.commonInfo = {
      userName: "",
      batchID: "",
      folderID: "",
      folderHash: "",
      samName: "",
      typeDE: "",
      company: "",
      project: "",
      status: "",
    }
  }

  ngOnInit() {
    this.url = new URL(window.location.href);
    this.pathname = this.url.pathname;
    let route = this.pathname.split("/");
    this.pathname = route[1];

    if (
      this.pathname !== "DataEntry" &&
      this.pathname !== "DataConflict" &&
      this.pathname !== "DataEntryView"
    ) {
      this.validURL = false;
    }

    //this.folderHash = this.url.searchParams.get("folderHash");
    this.folderHash = route[2];
    this.commonInfo.folderHash = this.folderHash;

    if (this.folderHash) {
      this.pathname === "DataEntryView"
        ? this.GetInfoProductionView(this.folderHash)
        : this.GetInfo(this.folderHash);

      // var toolbarHeight = (
      //   document
      //     .getElementsByClassName("mat-toolbar-row")
      //     .item(0) as HTMLElement
      // ).offsetHeight;
      this.heightWindow = document.documentElement.clientHeight + "px";

      if (this.isloaded)
        document.getElementById("viewportDE").style.height = this.heightWindow;
    } else {
      //SHOW SOMETHING THAT INDICATES AN ERROR
      console.log("ERROR: We don't have the folderHash");
    }
  }

  async GetInfo(folderHash: string) {
    this.commonControllerService.GetCommonInfo(folderHash).then((result) => {
      this.commonInfo = result;
      this.username = this.authService.getUsername();
      this.startDateTime = new Date();
      if (this.commonInfo.project == "CERTIMED") this.showTraduction = true;
      if (this.commonInfo.company == "Fivesplash") {
        this.translate.use("fr");
      }
      this.isloaded = true;
    });
  }

  async GetInfoProductionView(folderHash: string) {
    this.commonControllerService.GetDataEntryType(folderHash).then((result) => {
      this.commonInfo.typeDE = result;
      this.username = this.authService.getUsername();
      this.startDateTime = new Date();
      if (this.commonInfo.project == "CERTIMED") this.showTraduction = true;
      if (this.commonInfo.company == "Fivesplash") {
        this.translate.use("fr");
      }
      this.isloaded = true;
    })
    .catch(() => {
      console.log("Error obtaining data entry type");
    })
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  logout() {
    //Redirect to logout
    if (this.cookieService.check("token")) {
      this.cookieService.delete("token", "/");
      var location = window.location.href;
      var logOutWindow = window.open(
        "https://login.input4you.be/Login/Logout",
        "_blank"
      );

      setTimeout(() => {
        logOutWindow!.close();
        window.location.href = location;
      }, 100);
    }
  }
}
