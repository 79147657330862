import {
  Directive,
  ElementRef,
  EventEmitter,
  NgZone,
  Output,
} from "@angular/core";
import { Loader } from "@googlemaps/js-api-loader";
import { EMPTY, from } from "rxjs";
import { catchError, retry } from "rxjs/operators";

@Directive({
  selector: "[appGoogleMapsAutocomplete]",
})
export class GoogleMapsAutocompleteDirective {
  /* Loading the google maps api. */
  loader = new Loader({
    apiKey: "AIzaSyCUhL6zO6aJ3070MyNmn8v0RS17Q3JjQIw",
    libraries: ["places"],
    language: "en",
  });

  /* A type definition for the google maps autocomplete service. */
  mapsService: google.maps.places.Autocomplete;

  /* Setting the options for the autocomplete service. */
  options: google.maps.places.AutocompleteOptions = {
    fields: ["address_components"],
  };

  /* Creating an event emitter that will emit an array of address components. */
  @Output() onPlaceSelected = new EventEmitter<
    google.maps.GeocoderAddressComponent[]
  >();

  constructor(private element: ElementRef, private zone: NgZone) {}

  /**
   * We're loading the Google Maps API script, and if it fails, we're retrying it once. If it succeeds,
   * we're creating a new Google Maps Autocomplete object, and we're emitting the address components of
   * the selected place
   */
  ngOnInit(): void {
    from(this.loader.load())
      .pipe(
        catchError(() => {
          console.error("Failed when loadinng the Google Maps API script");
          return EMPTY;
        }),
        retry(1)
      )
      .subscribe((google) => {
        this.mapsService = new google.maps.places.Autocomplete(
          this.element.nativeElement,
          this.options
        );

        google.maps.event.addListener(this.mapsService, "place_changed", () => {
          const place = this.mapsService.getPlace();
          this.zone.run(() =>
            this.onPlaceSelected.emit(place.address_components)
          );
        });
      });
  }
}
