import { Component, OnInit } from '@angular/core';
import { FileServiceService } from '../services/file-service.service';
import { ImagesVM } from '../Interfaces/images';
import $ from "jquery";
import 'jquery.panzoom';
import { CommonControllerService } from '../services/common-controller.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css']
})
  
export class ImageViewerComponent implements OnInit {

  //Variables
  folderId: string;
  folderHash: string;
  boxId: string;
  nImages: string;
  images: ImagesVM[];
  heightWindow: string; //We use that for stablishing the height in the scroll.

  //Parent var
  parentFolderId: string;
  nParentImages: string;
  showParentImages: boolean = false;
  parentImages: ImagesVM[];
  showDeleteButton: boolean = true;


  constructor(private fileService: FileServiceService, private commonControllerService: CommonControllerService) { }

  ngOnInit() {

    this.folderHash = localStorage.getItem('folderHash');

    this.getImages();
    var toolbarHeight = (document.getElementsByClassName("mat-toolbar-row").item(0) as HTMLElement).offsetHeight;
    this.heightWindow = document.documentElement.clientHeight - toolbarHeight + "px";

    this.nImages = "";
    document.getElementById("viewport").style.height = this.heightWindow;

  }

  async getImages() {

    var info = await this.commonControllerService.GetCommonInfo(this.folderHash);
    this.folderId = info.folderID;
    this.boxId = info.batchID;
    this.images = await this.commonControllerService.GetImagesFromFolder(this.folderHash);
    this.nImages = this.images.length + "";
    this.fileService.updateImages(this.images);
    this.canDelete();

    if (info.project.toUpperCase() == "CERTIMED") {
      this.showParentImages = true;
      this.getParentImages();
    }
  }

  rotateImage(imageHash: string, index: number) {

    //Request to fileService for rotate the image.
    const url = this.fileService.rotateImageHash(imageHash, index);

    this.updateURLImage(url, imageHash);

  }

  changeColor(imageHash: string) {

    //Request to fileService for rotate the image.
    const url = this.fileService.changeColor(imageHash);

    this.updateURLImage(url, imageHash);

  }

  private updateURLImage(url: string, imageHash: string) {

    //We put the result
    var imgRotate = document.getElementById(imageHash) as HTMLImageElement
    imgRotate.src = url;

    //We need to update the VM of the images.
    var position = this.images.findIndex(x => x.hash == imageHash);

    if (position != -1) {
      this.images[position].image = url;
    }

    //We check if it exists in parent array
    var position = this.parentImages.findIndex(x => x.hash == imageHash);

    if (position != -1) {
      this.parentImages[position].image = url;
    }
  }

  initializeZoom(imageHash: string, isParent: boolean) {

    (this.getElement(imageHash, isParent) as any).panzoom({
      increment: 0.1,
      minScale: 1,
      panOnlyWhenZoomed: true,
      contain: 'invert'
    });
  }

  zoomIn(imageHash: string, isParent: boolean) {
    (this.getElement(imageHash, isParent) as any).panzoom('zoom');
  }

  zoomOut(imageHash: string, isParent: boolean) {
    (this.getElement(imageHash, isParent) as any).panzoom('zoom',true);
  }

  zoomReset(imageHash: string, isParent: boolean) {
    (this.getElement(imageHash, isParent) as any).panzoom('reset');
  }

  async getParentImages() {
    var parentInfo = await this.commonControllerService.GetImagesFromParentFolder(this.folderHash);

    this.parentFolderId = parentInfo["parentFolderId"];
    this.parentImages = parentInfo["images"];
    this.nParentImages = this.parentImages.length + "";
  }

  getElement(imageHash: string, isParent: boolean) {
    if (isParent) {
      return $(".parent").filter(function () {
        return $(this).attr("id") === imageHash;
      });
    }
    else
      return $("#" + imageHash)
  }

  changeDeleteFlag(imageHash: string, markDeleted: boolean) {
      //We put the image as deleted
      var position = this.images.findIndex(x => x.hash == imageHash);

      if (position != -1) {
        this.images[position].deleted = markDeleted;
      }

      this.fileService.updateImages(this.images);

      this.canDelete();
  }

  canDelete() {
    var canDelete = false;

    if (this.images.length > 1) {
      //We get the number of images that are not deleted.
      var imagesNotDeleted = this.images.filter(x => x.deleted == false).length;
      if (imagesNotDeleted > 1)
        canDelete = true;
    }

    this.showDeleteButton = canDelete;
  }
}
