import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ImagesVM } from '../Interfaces/images';
import { FileServiceService } from './file-service.service';
import { CommonInfoFolder } from '../Interfaces/commonInfoFolder';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CommonControllerService {

  apiUrl: string = environment.backendURL;
  constructor(private http: HttpClient, private fileServiceService: FileServiceService, private authService: AuthService) { }

  async SendBlock(folderHash, username, project, info, process:string) {
    const url = this.apiUrl + '/CommonApi/Block';

    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return await this.http.post(url, {
      "info": info,
      "username": username,
      "project": project,
      "folderHash": folderHash,
    }, { headers }).toPromise().then(data => {

      return "The " + process.toLocaleLowerCase().replace('_', " ") + " has been blocked successfully";
    })
      .catch(error => {
        console.log(error);
        return "A problem has occurred.";
      })
  }

  async UnlockUser(folderHash) {

    const url = this.apiUrl + '/CommonApi/UnlockUser?folderHash=' + folderHash;

    var result = "";

    result = await this.http.get(url).toPromise()
      .then((response) => {
        return "The user has been unlocked sucessfully";
      })
      .catch((error) => {
        console.log(error);
        return "There is a problem for unlock the DE.";
      });

    console.log(result, "valor");
    return result;
  }

  async GetImagesFromFolder(folderHash) {
    const url = this.apiUrl + '/CommonApi/GetImagesFromFolder?folderHash=' + folderHash;

    var arrayImagesVM: Array<ImagesVM> = new Array<ImagesVM>();

    var result;

    result = await this.http.get<Array<ImagesVM>>(url).toPromise()
      .then((response) => {
        response.forEach(image => {
          arrayImagesVM.push({
            fileName: image["fileName"],
            hash: image["hash"],
            image: this.fileServiceService.getBinaryImage(image["hash"]),
            deleted: image["deleted"]
          });
        })
        //arrayImagesVM = response;
        return arrayImagesVM;
      })
      .catch((error) => {
        console.log(error);
        return arrayImagesVM;
      });
    return result;
  }

  async GetDataEntryType(folderHash: string) {
    const url = `${environment.controllerUrl}/Project/CERTIMED/GetDataEntryProcesses?FolderHash=${folderHash}`;
    let dataEntryType;
    
    // Save folderhash in localStorage. This is neccessary when loading the images
    localStorage.setItem('folderHash', folderHash);
    
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    var result = await this.http.get(url, {headers, responseType: 'text'}).toPromise()
    .then(response => {
      return response;
    })
    .catch((error) => { console.log(error); return dataEntryType; });

    return result;
  }

  async GetCommonInfo(folderHash: string) {
    const username = this.authService.getUsername();
    const isAdmin = this.authService.hasPermissionForProject("certimed");
    const url = `${this.apiUrl}/CommonApi?username=${username}&isAdmin=${isAdmin}&folderHash=${folderHash}`;
    var commonInfo: CommonInfoFolder;

    var result = await this.http.get<CommonInfoFolder>(url).toPromise()
      .then((response) => {
        commonInfo = response;

        //Save the information in localstorage.
        localStorage.setItem('folderHash', commonInfo.folderHash);
        localStorage.setItem('batchID', commonInfo.batchID);
        localStorage.setItem('userName', commonInfo.userName);
        localStorage.setItem('folderID', commonInfo.folderID);
        localStorage.setItem('samName', commonInfo.samName);
        localStorage.setItem('typeDE', commonInfo.typeDE);
        localStorage.setItem('company', commonInfo.company);
        localStorage.setItem('project', commonInfo.project);
        localStorage.setItem('status', commonInfo.status);

        return commonInfo;

      })
      .catch((error) => { console.log(error); return commonInfo; });
    return result;
  }

  async GetImagesFromParentFolder(folderHash) {
    const url = this.apiUrl + '/CommonApi/GetImagesFromParentFolder?folderHash=' + folderHash;

    var arrayImagesVM: Array<ImagesVM> = new Array<ImagesVM>();
    var parentFolderID: string;

    var result;

    result = await this.http.get(url).toPromise()
      .then((response) => {
        parentFolderID = response["folderId"];

        response["images"].forEach(image => {
          arrayImagesVM.push({
            fileName: image["fileName"],
            hash: image["hash"],
            image: this.fileServiceService.getBinaryImage(image["hash"]),
            deleted: image["deleted"]
          });
        })

        var obj = {
          "parentFolderId": parentFolderID,
          "images": arrayImagesVM
        };

        return obj;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });

    return result;
  }

  async ChangeDeletedFlag(imageHash: string, markDeleted: boolean) {
    const url = this.apiUrl + '/CommonApi/ChangeDeletedFlag?imageHash=' + imageHash + '&markDeleted=' + markDeleted;

    return await this.http.get(url).toPromise().then(data => {
      return "Ok";
    })
    .catch(error => {
      console.log(error);
      return "A problem has occurred.";
    })
  }
}
