import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import moment from "moment";

/**
 * If the date is not valid, return an object with a key of IsValidDate and a value of the control's
 * value. Otherwise, return null.
 * @returns A function that returns a function that returns a ValidationErrors object or null.
 */
export function IsValidDate(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date = moment(control.value, "DD/MM/YYYY");

    if (!control.value) return null;

    return !date.isValid() && (control.value as string).trim() !== ""
      ? { IsValidDate: { value: control.value } }
      : null;
  };
}

/**
 * If the date is not between 2000 and 2099, return an error object with a property called
 * DateHas20XXYear and a value of the control's value.
 * @returns A validator function that takes an AbstractControl and returns a ValidationErrors object or
 * null.
 */
export function DateHas20XXYear(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date = moment(control.value, "DD/MM/YYYY");

    if (!control.value) return null;

    return !date.isBetween("2000-01-01", "2099-12-31", "year", "[]") &&
      (control.value as string).trim() !== ""
      ? { DateHas20XXYear: { value: control.value } }
      : null;
  };
}

/**
 * If the date is after today, return an error object, otherwise return null.
 * @returns A function that returns a function that returns a ValidationErrors object or null.
 */
export function IsTodayOrPastDate(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date = moment(control.value, "DD/MM/YYYY");

    if (!control.value) return null;

    return date.isAfter(moment()) && (control.value as string).trim() !== ""
      ? { IsTodayOrPastDate: { value: control.value } }
      : null;
  };
}
