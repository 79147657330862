import { Component, OnInit, HostListener, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IdentificationControllerService } from '../services/identification-controller.service';
import { BlockModalComponent } from '../block-modal/block-modal.component';
import { CommonControllerService } from '../services/common-controller.service';

@Component({
  selector: 'app-nnidentification-folder',
  templateUrl: './nnidentification-folder.component.html',
  styleUrls: ['./nnidentification-folder.component.css']
})
export class NNIdentificationFolderComponent implements OnInit {

  @Input('startDateTime') startDateTime: Date;

  //VAR
  disableSend: boolean = false;
  disableBlock: boolean = false;
  disableClose: boolean = false;
  folderHash: string;
  user: string;

  //LIST BOX
  selectOptions: Array<string>;

  identificationform = new FormGroup({
    identification: new FormControl(),
    label: new FormControl('', [Validators.required])
  });

  //SHORTCUTS
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {

      if (event.ctrlKey && event.code == "KeyS") { //CTRL + S
        //We need to save it
          event.preventDefault()
          event.stopImmediatePropagation();
          if (!this.disableSend) {
            this.identificationform.markAllAsTouched();
            this.onSubmit(this.identificationform.value);
          }
      }
      else if (event.ctrlKey && event.code == "KeyB") { //CTRL + B
        //We need to block it
        event.preventDefault()
        event.stopImmediatePropagation();
        if (!this.disableBlock)
          this.block();
      }
      else if (event.ctrlKey && event.code == "KeyQ") { //CTRL + Q
        //We need to close it
        event.preventDefault()
        event.stopImmediatePropagation();
        if (!this.disableClose)
          this.close();
      }

  }

  constructor(private notification: MatSnackBar, public dialog: MatDialog, private identificationControllerService: IdentificationControllerService, private commonControllerService: CommonControllerService) { }

  ngOnInit() {
    this.selectOptions = new Array<string>("Other", "SEPA");

    this.folderHash = localStorage.getItem('folderHash');
    this.startInfo();
  }

  async close() {
    this.disableClose = true;
    this.disableSend = true;
    this.disableBlock = true;
    var response = await this.commonControllerService.UnlockUser(this.folderHash);
    this.notification.open(response, "", {
      duration: 2000,
      panelClass: "centeredNotification"

    }).afterDismissed().toPromise().then((response) => { self.close(); this.disableClose = false; this.disableSend = false; this.disableBlock = false; });

  }

  async block() {
    this.disableClose = true;
    this.disableBlock = true;

    const dialogRef = this.dialog.open(BlockModalComponent, {
      position: { top: '5px' },
      autoFocus: false,
      data: "Identificacion"

    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          console.log(result);

          if (result["reason"] != null && result["reason"] != undefined && result["remarks"] != null && result["remarks"] != undefined) {
            this.commonControllerService.SendBlock(this.folderHash, this.user, "NN", result, "MANUAL_IDENTIFICATION_FOLDER").then(response => {
              this.notification.open(response, "", {
                duration: 2000,
                panelClass: "centeredNotification"

              }).afterDismissed().toPromise().then(x => {
                if (response == "The identification has been blocked successfully") {
                  this.close();
                }
                else {
                  this.disableClose = false;
                  this.disableBlock = false;
                }
              });
            });
          }
          else {
            this.disableClose = false;
            this.disableBlock = false;
          }
        }
      },
      error => {
        console.log('The dialog was closed ERROR ' + error);
      }
    );

    dialogRef.backdropClick().subscribe(result => {
      this.disableBlock = false;
      this.disableClose = false;
    })

    dialogRef.keydownEvents().subscribe(keyboardEvent => {
      console.log(keyboardEvent);
      if (keyboardEvent.code == "Escape") {
        this.disableBlock = false;
        this.disableClose = false;
      }
    })
  }

  async onSubmit(identificationform) {
    console.log(identificationform);
    this.disableSend = true;
    console.log(this.disableSend);
    console.log(identificationform.invalid);
    if (this.identificationform.invalid) {
      console.log("invalid");
      this.disableSend = false;
    }
    else {
      var result = await this.identificationControllerService.SendForm(identificationform, this.user, this.folderHash, "NN", this.startDateTime);
      this.disableSend = false;
      this.notification.open(result, "", {
        duration: 2000,
        panelClass: "centeredNotification"

      }).afterDismissed().toPromise().then(x => {
        if (result == "The form has been filled susccessfully.") {
          this.close();
        }
      });
    }
    return false;
  }

  async startInfo() {
    var info = await this.commonControllerService.GetCommonInfo(this.folderHash);
    this.user = info.samName;
  }

  getErrorRequiredMessage(field: AbstractControl) {
    return field.hasError('required') ? 'You need to enter a value' : '';
  }

}
