import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { certimedDCInfo, certimedModel } from '../Interfaces/certimedDCInfo';
import { rentaModel } from '../Interfaces/rentaDCInfo';
import { environment } from '../../environments/environment';
import { FileServiceService } from './file-service.service';

@Injectable({
  providedIn: 'root'
})
export class DataEntryControllerService {

  apiUrl: string = environment.backendURL;
  constructor(private http: HttpClient, private fileService: FileServiceService) { }

  async SendForm(dataEntryForm, username, folderHash, project, startDateTime, status) {
    const url = this.apiUrl + '/DataEntryApi';

    //const headers = new HttpHeaders().set("Access-Control-Allow-Origin", "*");
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return await this.http.post(url, {
      "dataEntry": dataEntryForm,
      "username": username,
      "project": project,
      "folderHash": folderHash,
      "startDateTime": startDateTime,
      "updatedImages": this.fileService.listImages,
      "status": status
    }, { headers }).toPromise().then(data => {
        console.log("POST Request is successful ", data);
        return "The form has been filled susccessfully.";
      })
      .catch(error => {
          console.log(error);
          return "A problem has occurred.";
      })
    }

  async ObtainDEModel(folderHash: string, project: string, isProductionView: boolean) {
    const url = this.apiUrl + '/DataEntryApi/ObtainDEInfo?folderHash=' + folderHash + '&project=' + project + '&isProductionView=' + isProductionView;

    var certimedModel: Array<certimedModel> = new Array<certimedModel>();
    var rentaModel: Array<rentaModel> = new Array<rentaModel>();

    //const headers = new HttpHeaders().set("Access-Control-Allow-Origin", "*");
    //headers.append("Content-Type", "application/json");

    var result;

    if (project == "CERTIMED") {
      result = await this.http.get<certimedModel>(url).toPromise()
        .then((response) => {

          if (response["dataEntry1"] && response["dataEntry1"] != null)
            certimedModel.push(JSON.parse(response["dataEntry1"]))

          if (response["dataEntry2"] && response["dataEntry2"] != null)
            certimedModel.push(JSON.parse(response["dataEntry2"]));

          if (isProductionView) {
            if (response["dataEntry3"] && response["dataEntry3"] != null)
              certimedModel.push(JSON.parse(response["dataEntry3"]));
            }

            return certimedModel;
        })
        .catch((error) => {
          console.log(error);
          if (project == "CERTIMED")
            return certimedModel;
        });
    }
    if (project == "RENTA") {
      result = await this.http.get<rentaModel>(url).toPromise()
        .then((response) => {

          rentaModel.push(JSON.parse(response["dataEntry1"]));
          rentaModel.push(JSON.parse(response["dataEntry2"]));

          return rentaModel;
        })
        .catch((error) => {
          console.log(error);
          if (project == "RENTA")
            return rentaModel;
        });
    }
    return result;
  }

  async GetFolderIdentification(folderHash) {
    const url = environment.controllerUrl + '/Document/Identification?folderHash=' + folderHash;

    var folderIdentification: Object = new Object();

    var result;

    result = await this.http.get(url).toPromise()
      .then((response) => {
          folderIdentification = JSON.parse(response["ids"][response["ids"].length - 1]);
          return folderIdentification;
      })
      .catch((error) => {
        console.log(error);
        return folderIdentification;
      });

    return result;
  }

  async Search(searchInfo, folderHash, typeSearch="") {
    const url = this.apiUrl + '/DataEntryApi/Search';

    //const headers = new HttpHeaders().set("Access-Control-Allow-Origin", "*");
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return await this.http.post(url, {
      "searchInfo": searchInfo,
      "folderHash": folderHash,
      "typeSearch": typeSearch
    }, { headers }).toPromise().then(data => {
      console.log("POST Request is successful ", data);
      return data;
    })
      .catch(error => {
        console.log(error);
        return null;
        //return "A problem has occurred.";
      })
  }
  
  async GetDoctorInfo(folderHash){
    const url = this.apiUrl + '/DataEntryApi/GetDoctorInfo?folderHash=' + folderHash;

    var doctorInfo: Object = new Object();

    //const headers = new HttpHeaders().set("Access-Control-Allow-Origin", "*");

    var result;

    result = await this.http.get(url).toPromise()
      .then((response) => {
          doctorInfo = response;
          return doctorInfo;
      })
      .catch((error) => {
        console.log(error);
        return doctorInfo;
      });

    console.log(result, "valor");
    return result;
  }

}
