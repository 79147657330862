import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import Diagnose from "../../assets/JSON/Diagnose.json";

/**
 * If the value of the control is not in the list of Diagnose, return an error object with the key
 * "ValueInList" and the value of the control.
 * @returns The validator function is being returned.
 */
export function ValueInListValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const description = (control.value as string).split(",")[0].toUpperCase();

    return Diagnose.some((entry) => entry.Description.includes(description))
      ? null
      : { ValueInList: { value: control.value } };
  };
}
