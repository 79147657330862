import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataEntryControllerService } from '../services/data-entry-controller.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { certimedSearchInfo } from '../Interfaces/certimedDCInfo';

@Component({
  selector: 'app-certimed-data-entry-search-modal',
  templateUrl: './certimed-data-entry-search-modal.component.html',
  styleUrls: ['./certimed-data-entry-search-modal.component.css']
})
export class CertimedDataEntrySearchModalComponent implements OnInit {

  folderHash: string;

  displayedColumns: string[] = ['doctorName', 'doctorPhoneNumber', 'rizivNumber'];
  dataSource = new Array<certimedSearchInfo>();
  showResult: boolean = false;
  totalResult: number = 0;
  totalRows: number = 0;

  search = new FormGroup({
    doctorName: new FormControl(''),
    doctorPhoneNumber: new FormControl(''),
    rizivNumber: new FormControl(''),
  })

  constructor(public dialogRef: MatDialogRef<CertimedDataEntrySearchModalComponent>,@Inject(MAT_DIALOG_DATA) public data,
    private dataEntryControllerService: DataEntryControllerService, private notification: MatSnackBar) { }


  doubleClick(value: certimedSearchInfo) {
    this.dialogRef.close(value);
  }

  ngOnInit() {
    this.folderHash = this.data.commonInfo.folderHash;
  }

  async onSubmit(resultForm) {

    console.log(resultForm);
    this.showResult = true;
    if (resultForm["doctorName"] == "" && resultForm["doctorPhoneNumber"] == "" && resultForm["rizivNumber"] == "") {
      this.notification.open("Empty search is not valid, you need to fill at least one field.", "", { duration: 1500 });
      this.dataSource = new Array<certimedSearchInfo>({ doctorName: "", doctorPhoneNumber: "Not Found", rizivNumber: "" });
    }
    else {
      var result = await this.dataEntryControllerService.Search(resultForm, this.folderHash, "DOCTOR");
      this.dataSource = result["result"];
      this.totalResult = result["totalResult"];
      this.totalRows = this.dataSource.length;
      //this.dataSource = await this.dataEntryControllerService.Search(resultForm, this.folderHash);
      if (this.dataSource.length == 0) {
        this.dataSource.push({ doctorName: "", doctorPhoneNumber: "Not Found", rizivNumber: "" });
      }
    }
    //this.notification.open(result, "", {
    //  duration: 2000,
    //});
  }

}
