import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-input-maps-componet",
  templateUrl: "./input-maps-componet.component.html",
  styleUrls: ["./input-maps-componet.component.css"],
})
export class InputMapsComponetComponent implements OnInit {
  /* Creating a new event emitter that will emit an address object. */
  @Output("onAddressSelected") addressInfo = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  /**
   * It takes the address data from the Google Maps API and emits it to the parent component
   * @param {google.maps.GeocoderAddressComponent[]} location - google.maps.GeocoderAddressComponent[]
   */
  emitAddress(location: google.maps.GeocoderAddressComponent[]) {
    let addressData = {
      street: "",
      street_number: "",
      city: "",
      postal_code: "",
      country: "",
      country_code: "",
    };

    location.forEach((value) => {
      const longNameValue = value.long_name;
      const shortNameValue = value.short_name;

      // Search for the address
      if (value.types.includes("route")) addressData.street = longNameValue;

      // Search street number
      if (value.types.includes("street_number"))
        addressData.street_number = longNameValue;

      // Search the city
      if (value.types.includes("locality")) addressData.city = longNameValue;

      // Search the postal code
      if (value.types.includes("postal_code"))
        addressData.postal_code = longNameValue;

      // Search the country
      if (value.types.includes("country")) {
        addressData.country = longNameValue;
        addressData.country_code = shortNameValue;
      }
    });

    this.addressInfo.emit(addressData);
  }
}
